<template>
  <div>
    <v-container>
      <!-- <div class="text-center mt-12" v-if="loading">
      <v-progress-circular size="35" indeterminate color="primary" />
    </div> -->
      <!-- <div class="text-center mt-12" v-if="sync">
        <v-progress-circular size="35" indeterminate color="primary" />
        <br />
        <span class="text-center">
            Sincronizando produtos no carrinho local...
        </span>
    </div> -->
      <center>
        <v-card width="450" class="justify-center mt-5 mb-5 text-center">
          <div class="px-3 px-md-10 py-8">
            <v-avatar width="170" height="110" tile class="mb-5">
              <img src="@/assets/images/MVO.png" alt="" />
            </v-avatar>
            <!-- <h3 class="mb-2 text-center">MUTUE VENDAS ONLINE</h3> -->
            <!-- <h5
            class="font-600 grey--text text--darken-3 text-sm mb-9 text-center"
          >
            Login com email e password
          </h5> -->
            <p class="text-14 mb-1 text-left">Email/Telefone</p>
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="Email ou telefone"
              class="mb-4"
              v-model="user.email"
              @keyup.enter="login()"
            ></v-text-field>
            <p class="text-14 mb-1 text-left">Senha</p>
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="Senha"
              :append-icon="showpassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showpassword ? 'text' : 'password'"
              @click:append="showpassword = !showpassword"
              class="mb-4"
              v-model="user.password"
              @keyup.enter="login()"
            ></v-text-field>
            <v-btn
              block
              color="secondary"
              :disabled="loading || !user.password || !user.email"
              class="text-uppercase font-600"
              @click="login()"
              >Iniciar sessão</v-btn
            >
            <v-alert
              style="color: green"
              outlined
              dismissible
              transition="scale-transition"
              text
              dense
              v-if="alert.type == 'success'"
              type="success"
              align="center"
            >
              {{ alert.text }}
            </v-alert>
            <!-- <h4>
            {{ errors.email }}
          </h4> -->
            <!-- <span v-for="(index, error) in errors" :key="index++">
            <h6>{{ error }}</h6>
          </span> -->
            <v-alert
              style="color: red"
              dismissible
              transition="scale-transition"
              outlined
              dense
              v-if="alert.type == 'error'"
              type="error"
            >
              {{ errors.email }}
              {{ errors.message }}
              {{ alert.text }}
            </v-alert>
            <!-- 
          <v-col cols="10" lg="8" class="mx-auto">
            <div class="d-flex align-center my-1">
              <v-divider></v-divider>
              <span class="mx-4">on</span>
              <v-divider></v-divider>
            </div>
          </v-col>
          <v-btn
            block
            dark
            color="indigo"
            class="text-capitalize font-600 mb-4"
          >
            <v-icon left class="me-3">mdi-facebook</v-icon>
            Continuar com facebook
          </v-btn> -->
            <!-- <v-btn
                      block
                      dark
                      color="blue darken-2"
                      class="text-capitalize font-600 mb-4"
                    >
                      <v-icon left class="me-3">mdi-google</v-icon>
                      Continue com Google
                    </v-btn> -->
            <div class="text-14 text-center my-3" v-if="!loading">
              Ainda não tem conta?
              <router-link
                :to="{ name: 'PortalRegister' }"
                class="grey--text text-uppercase text--darken-4 font-600"
                >Criar conta</router-link
              >
            </div>
          </div>
          <v-row v-if="loading && dialogSucessoUserLogado == false">
            <v-col cols="12">
              <v-progress-linear
                indeterminate
                color="secondary"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <div class="py-4 grey lighten-2">
            <div class="text-center">
              <span class="grey--text text--darken-1"
                >Esqueci minha Senha
                <router-link
                  :to="{ name: 'ResetPassword' }"
                  class="ms-2 grey--text text--darken-4 font-600"
                  >Recuperar senha!</router-link
                >
              </span>
            </div>
          </div>
        </v-card>
      </center>
    </v-container>

    <v-dialog
      name="dialog-logout-sucesso"
      v-model="dialogSucessoUserLogado"
      max-width="300"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="50"
            >mdi-check-circle</v-icon
          >
          <h6 class="mt-2">Login efetuado com sucesso!</h6>

          <v-col cols="12">
            <span> Carregando dados...</span>
          </v-col>
          <v-progress-linear
            indeterminate
            color="secondary"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { LOGIN_URL } from "@/configs/api";
export default {
  data: () => ({
    errors: [],
    isInputValid: true,
    dialogSucessoUserLogado: false,
    sync: false,
    cart: [],
    showpassword: false,
    phoneValidationRules: [
      (val) => !!val || "Email é obrigatório",
      (val) => /.+@.+\..+/.test(val) || "Email inválido",
    ],
    emailValidationRules: [
      (val) => !!val || "Email é obrigatório",
      (val) => /.+@.+\..+/.test(val) || "Email inválido",
    ],
    email: "",
    step: 1,
    user: {
      email: "",
      password: "",
    },
    dialogResetPassword: false,
    overlay: false,
    dialog: false,
    showpassword: false,
    valid: true,
    alert: {
      text: "",
      type: "",
      text2: "",
      type2: "",
    },
    utilizador: {},
    user_roles: [],
    roles: {},
    sobrenome: "",
    dialogTermo: true,
    aceitar_termo: false,
    loading: false,
  }),

  computed: {},
  created() {},

  mounted() {
    // this.Laravel = window.Laravel;
    if (sessionStorage.getItem("cart")) {
      this.cart = sessionStorage.getItem("cart");
    }
    if (
      sessionStorage.getItem("TOKEN") != null ||
      localStorage.getItem("TOKEN") != null
    ) {
      this.$router.push("/");
    }
  },

  methods: {
    isValidEmail(email) {
      // Lógica de validação de email
      // Retorna true se o email for válido, caso contrário, false
      // Aqui, uma validação básica está sendo feita apenas para ilustração
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    isValidPhoneNumber(phone) {
      // Lógica de validação de telefone
      // Retorna true se o telefone tiver exatamente 9 dígitos numéricos, caso contrário, false
      return /^\d{9}$/.test(phone);
    },
    checkInputValidity() {
      // Verifica se o valor inserido é um email válido ou um número de telefone válido
      const input = this.user.email;

      if (this.isValidEmail(input) || this.isValidPhoneNumber(input)) {
        this.isInputValid = true;
      } else {
        this.isInputValid = false;
      }
    },
    invalidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },
    resetPassword() {
      this.loading = true;
      let email = this.email;
      let formData = new FormData();
      formData.append("email", email);

      axios
        .post("/forgot-password-reset", formData)
        .then((response) => {
          setTimeout(() => {
            this.loading = false;
            this.alert.text2 =
              "Foi enviado o link de recuperação da senha no seu email!";
            this.alert.type2 = "success";
            this.email = "";
          }, 1000);
        })
        .catch((error) => {
          this.alert.text2 = error.response.data.errors;
          this.alert.type2 = "error";
          this.loading = false;
          this.dialogResetPassword = true;
        });
    },
    showDialogResetPassword() {
      this.dialogResetPassword = true;
    },
    async login() {
      this.loading = true;
      await axios.get(`${LOGIN_URL}/sanctum/csrf-cookie`);
      // return;
      let url = `${LOGIN_URL}/user/login`;
      this.alert.type = null;
      this.alert.text = null;
      axios
        .post(url, this.user)
        .then((response) => {
          if (response.data.token) {
            this.dialogSucessoUserLogado = true;
            localStorage.setItem("TOKEN", response.data.token);
            let userLogado = JSON.stringify(response.data);
            localStorage.setItem("userLogado", userLogado);

            setTimeout(() => {
              this.sync = false;
              this.$router.push({
                name: "ProdutosEmDestaque",
              });
            }, 3800);
            setTimeout(() => {
              window.location.reload();
            }, 4500);
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.alert.type = "error";
            // this.alert.text = error.response.data;
            this.errors = error.response.data;
            console.log("Erro do servidor: ", error.response.data);
          }
        });
    },
  },
};
</script>

<style scoped>
.background-image {
  background-image: url("https://www.pngitem.com/pimgs/m/522-5229044_e-commerce-store-png-transparent-png.png");
  background-size: cover;
  /* adjust as necessary */
  background-position: center center;
  /* adjust as necessary */
  background-repeat: no-repeat;
  /* adjust as necessary */
}

#content {
  margin-top: 70px;
}

.section-title {
  padding-bottom: 0px;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 170px;
  height: 3px;
  background: #2b42a8;
  bottom: 0;
  left: calc(50% - 50%);
}

.red {
  color: red;
}

.green {
  color: green;
}
</style>
