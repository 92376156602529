// export const LOGIN_URL = 'http://10.10.6.92:8000/api'
// http://10.10.6.98:8000

// export const LOGIN_URL = 'http://192.168.30.24:82/api'
// export const SERVIDOR = 'http://192.168.30.24:82/'
// export const BASE_URL = 'http://192.168.30.24:82/api/portal'

export const LOGIN_URL = 'https://mutue-negocios.ao/api'
export const SERVIDOR = 'https://mutue-negocios.ao/'
export const BASE_URL = 'https://mutue-negocios.ao/api/portal'

// export const LOGIN_URL = 'http://10.10.1.19:82/api'
// export const BASE_URL = 'http://10.10.1.19:82/api/portal'

// export const LOGIN_URL = 'http://172.10.15.71:8000/api'
// export const SERVIDOR = 'http://172.10.15.71:8000/'
// export const BASE_URL = 'http://172.10.15.71:8000/api/portal'

// export const LOGIN_URL = 'http://127.0.0.1:8000/api'
// export const BASE_URL = 'http://127.0.0.1:8000/api/portal'

// export const LOGIN_URL = 'http://10.10.1.19:82/api'
// export const BASE_URL = 'http://10.10.1.19:82/api/portal'

// export const LOGIN_URL = 'http://192.168.219.1:8000/api'
// export const BASE_URL = 'http://192.168.219.1:8000/api/portal'

// export const LOGIN_URL = 'http://127.0.0.1:8000/api'
// export const BASE_URL = 'http://127.0.0.1:8000/api/portal'

// export const LOGIN_URL = 'http://10.10.1.19:82/api'
// export const BASE_URL = 'http://10.10.1.19:82/api/portal'
export const PORT = ''
// export const PORT = ':8000/api/portal'
// export const SERVER = 'http://10.10.1.19:82'
// export const API_VERSION = 'v1'
